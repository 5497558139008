import React, { useState, useEffect, useRef } from 'react';
import configData from "../../appsettings.json";
import { handleError, GetModulesRules } from "../../js/Utils";
import { WebMenuVoices } from './components/WebMenuVoices';
import { RenderByPlaceholder } from "./components/WebMenuRenderType/RenderByPlaceholder"
import { HeaderLogo } from './components/Header/HeaderLogo';
import Cookies from 'universal-cookie';
import { Login } from '../Auth/Login';

export const Header = ({ websiteId }) => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [MyKeyHeader, setMyKeyHeader] = useState(null);
    const [myHeader, setMyHeader] = useState(null);
    const [rules, setRules] = useState(null);

    const headerRef = useRef();

    useEffect(() => {
        if (cultureCode) {
            setMyKeyHeader("HeaderItems_" + cultureCode + "_" + websiteId);
        }
    }, [cultureCode, websiteId]);


    useEffect(() => {
        const callGetModuleRules = async () => {
            const moduleRules = await GetModulesRules();
            setRules(moduleRules);
        };
        callGetModuleRules();

        if (MyKeyHeader) {
            GetHeaderVoices();
        }

        const resizeObserver = new ResizeObserver(() => {
            // Do what you want to do when the size of the element changes
            try {
                let headerHeight = headerRef.current.clientHeight;
                let marginTop = headerHeight;

                let childrenContainer = document.getElementById("childrenContainer");

                if (childrenContainer) {
                    childrenContainer.style.paddingTop = marginTop + 'px';
                }

            } catch (ex) {
                console.error(ex);
            }
        });
        resizeObserver.observe(headerRef.current);
    }, [MyKeyHeader]);


    function GetHeaderVoices() {
        let strMyHeader = sessionStorage.getItem(MyKeyHeader);
        if (!strMyHeader) {

            const requestOption = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            let cc = cultureCode;
            if (!cc)
                cc = 'it-IT';

            fetch(`${configData.Settings.CommonApi_BaseUrl}website/getwebsitemenu/0/${websiteId}/${cc}`, requestOption)
                .then((res) => handleError(res))
                .then((item) => {
                    const headerItems = { items: item };
                    setMyHeader(headerItems);
                    sessionStorage.setItem(MyKeyHeader, JSON.stringify(headerItems));
                })
                .catch((err) => {
                    console.error(err);
                });

        } else {
            let jCurrentHeader = JSON.parse(strMyHeader);
            setMyHeader(jCurrentHeader);
        }
    }


    return (
        <>
            <header id="header" className="fixed-top" ref={headerRef}>
                {
                    myHeader && myHeader.items.map((item, key) => {
                        return <WebMenuVoices key={key} item={item} rules={rules} />
                    })
                }
                <div className="modal tp-search-input fade" id="hamburgerMenuModal" style={{ zIndex: 2050 }} aria-labelledby="EngineModifyActivty" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen-xxl-down">
                        <div className="modal-content">
                            <div className="modal-header p-0" style={{
                                backgroundColor: '#0d1b48',
                            }}>
                                <div className="h4 text-center w-100 mt-2">
                                    <HeaderLogo />
                                </div>
                                <button type="button" style={{ filter: 'brightness(0) invert(1)' }} className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body " style={{
                                backgroundColor: '#0d1b48',
                            }}>
                                {
                                    myHeader && myHeader.items.map((item, key) => {
                                        return <WebMenuVoices modal={true} key={key} item={item} rules={rules} />
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="modal fade" data-backdrop="false" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-5 pt-0">
                                <Login />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
