import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import configData from "../../appsettings.json";
import { RegistrationEndUserForm } from "./components/RegistrationEndUserForm";
import Cookies from 'universal-cookie';

export const RegistrationUser = () => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [isDisabledForm, setIsDisabledForm] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMessage, setIsLoadingMessage] = useState(false);
    const [showTokenPanel, setShowTokenPanel] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [messagge, setMessagge] = useState('');

    const [inputData, setInputData] = useState({});
    

    function saveUser() {
        setIsLoading(true);
        setIsError(false);
        setShowTokenPanel(false);
        setErrorMsg('');

        const callRegisterEndUser = async () => {
            const response = registerEndUser();
        }

        callRegisterEndUser();
    }

    async function registerEndUser() {
        if (configData.Settings.EndUser && configData.Settings.EndUser.StakeholderId)
            inputData["stakeholderId"] = configData.Settings.EndUser.StakeholderId;
        if (configData.Settings.EndUser && configData.Settings.EndUser.ProfileId)
            inputData["profileId"] = configData.Settings.EndUser.ProfileId;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/userregistration', requestOption);
        const response = await fetchedRes.json();
                        
        if (response.success && response.send) {
            setIsLoadingMessage(true);
            setMessagge(t("Login:CheckYourEmail"));
        }
        else if (response.success && response.exist) {
            setIsError(true);
            setErrorMsg(t("Login:MailAlreadyExists"));
        }
        else if (!response.success && response.promoteCode) {
            setIsError(true);
            setErrorMsg(t("Login:PromoteCodeNotValid"));
        }
        else {
            setIsError(true);
            setErrorMsg(t("Login:RegistrationErrorMessage"));
        }
    }

    return (
        <>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-12 text-left">
                        <h3>{t("Login:Registration")}</h3>
                    </div>
                    <div className="col-12 text-center">
                        <div className="row">
                            {!isLoading &&
                                <RegistrationEndUserForm handeIsValidForm={setIsDisabledForm} handleInputData={setInputData} cultureCode={cultureCode} showPromoteCode={configData.Settings.Login && configData.Settings.Login.PromoteCode} mandatoryPromoteCode={configData.Settings.Login && configData.Settings.Login.MandatoryPromoteCode} />
                            }
                            {(!isLoadingMessage && !isError) &&
                                <div className="col-12 mb-3">
                                    <button className="btn btn-sm buttonCustom" disabled={isDisabledForm} onClick={saveUser}>
                                        {
                                            !isLoading ? <>{t("Button:Registration")}</>
                                                : <div className="spinner-border spinner-border-sm text-light" role="status" style={{ 'fontSize': '9px' }}>
                                                    <span className="sr-only"></span>
                                                </div>
                                        }
                                    </button>
                                </div>
                            }
                        </div>
                        {isLoadingMessage && 
                            <div className="text-success mt-1">
                                {messagge}
                            </div>
                        }
                        {isError &&
                            <div className="text-danger mt-1">
                                {errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}