import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { M3Icon } from '../../../Common/M3Icon';
import { formatPrice, getPaymentTypeIconName } from '../../../../js/Utils.js';
import configData from "../../../../appsettings.json";

export const HeaderGiftCards = ({ item, modal }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [giftCardAmount, setGiftCardAmount] = useState(null);
    const [totalWelfareCredit, setTotalWelfareCredit] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState('mybook/giftcards');


    useEffect(() => {
        const getCartSummaryInfo = async () => {
            callGetCartSummaryInfo();
        }
        getCartSummaryInfo();
    }, [item]);


    async function callGetCartSummaryInfo() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}ShoppingCart/GetCartSummaryInfo`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.giftCardAmount) {
                let totalGiftPrice = formatPrice(response.giftCardAmount.displayPrice, response.giftCardAmount.displayPriceCurrency, cultureName);
                setGiftCardAmount(totalGiftPrice);
            }

            if (response.totalWelfareCredit) {
                if (response.totalWelfareCredit.displayPriceCurrency === "P") {
                    let totalWelfareCreditPoints = `${response.totalWelfareCredit.displayPrice} ${response.totalWelfareCredit.displayPriceCurrency}`;
                    setTotalWelfareCredit(totalWelfareCreditPoints);
                } else {
                    let totalWelfareCredit = formatPrice(response.totalWelfareCredit.displayPrice, response.totalWelfareCredit.displayPriceCurrency, cultureName);
                    setTotalWelfareCredit(totalWelfareCredit);
                }
            }
        } else {
            setGiftCardAmount(null);
        }
    }

    function getClassName(className) {
        if (!className)
            return "";

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return "";
    }


    return (
        <>
            {giftCardAmount &&
                <a className={getClassName(item.className) + ' d-none d-sm-flex'} href={redirectUrl}>
                    {<><M3Icon iconName="Wallet" /><span className="h065 pl-2">{giftCardAmount}</span></>}

                    {totalWelfareCredit && <>&nbsp;&nbsp;&nbsp;&nbsp;<img src={`${configData.Settings.CDN_Common}/images/ico/payment_type/${getPaymentTypeIconName(8)}.png`} alt="Punti Welfare" style={{ width: '20px' }} /><span className="h065 pl-2">{totalWelfareCredit}</span></>}
                </a>
            }
            {giftCardAmount &&
                <a className={getClassName(item.className) + ' d-flex d-sm-none p-0'} href={redirectUrl}>
                    {<><M3Icon iconName="Wallet" /><span className="h065">{giftCardAmount}</span></>}

                    {totalWelfareCredit && <><img src={`${configData.Settings.CDN_Common}/images/ico/payment_type/${getPaymentTypeIconName(8)}.png`} alt="Punti Welfare" style={{ width: '20px' }} /><span className="h065">{totalWelfareCredit}</span></>}
                </a>
            }
        </>
    );
}
