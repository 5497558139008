import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Auth/AuthProvider';

export const HeaderLogout = ({ item, modal }) => {
    const { onLogout } = useAuth();

    const handleLogout = async e => {
        e.preventDefault();
        onLogout();
    }

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return '';
    }

    return (<>
        <a className={getClassName(item.className) + " " + "d-none d-sm-block"} onClick={handleLogout}>
            <div type="button" className="btn text-danger" data-bs-toggle="tooltip" data-bs-title="Tooltip on bottom">
                <span className="material-icons">power_settings_new</span>
            </div>
        </a>



        <div className={`d-flex d-sm-none align-items-center`} >


            <a onClick={handleLogout} className="p-0 pr-2">
                <div type="button" className="text-danger" data-bs-toggle="tooltip" data-bs-title="Tooltip on bottom">
                    <span className="material-icons" style={{
                        fontSize: '2rem'
                    } }>power_settings_new</span>
            </div>
        </a>

 
           
        
        </div>
    </>);
}
