import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from './Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { GetModulesRules, getWebsiteId } from "../js/Utils";
import { StructureSearchEngine } from './Product/SearchEngine/StructureSearchEngine';
import { ActivitySearchEngine } from './Product/SearchEngine/ActivitySearchEngine';
import { FlightSearchEngine } from './Product/SearchEngine/FlightSearchEngine';
import { CarSearchEngine } from './Product/SearchEngine/CarSearchEngine';
import { imageEnginePage } from '../js/CDNUtility';
import configData from "./../appsettings.json";
import { WebContent } from "./WebContent/WebContent";
import { TrainSearchEngine } from './Product/SearchEngine/TrainSearchEngine';
import { M3Icon } from "./Common/M3Icon"

export const Engine = () => {
    const { t } = useTranslation();
    const [rules, setRules] = useState(null);
    const [dynamicImages, setDynamicImages] = useState(null);
    const [dynamicVideo, setDynamicVideo] = useState(null);
    const [moduleSelected, setModuleSelected] = useState('HOTEL');
    const [imageTitle, setImageTitle] = useState('');
    const [imageDescription, setImageDescription] = useState('');

    let { getModuleSelected } = useAuth();

    const engineContainerRef = useRef();
    const [bannerStyle, setBannerStyle] = useState({ marginTop: '0px', marginRight: "35px", marginLeft: "35px", maxWidth: "97%" });

    useEffect(() => {

        const callGetModuleRules = async () => {
            const moduleRules = await GetModulesRules();
            setRules(moduleRules);
        };

        const resizeObserver = new ResizeObserver(() => {
            // Do what you want to do when the size of the element changes
            try {
                let engineHeight = engineContainerRef.current.clientHeight;
                let marginTop = engineHeight - 100;

                if (marginTop > 0) {
                    setBannerStyle({
                        ...bannerStyle,
                        marginTop: marginTop + 'px'
                    });
                } else {
                    setBannerStyle({
                        ...bannerStyle,
                        marginTop: '0px'
                    });
                }

            } catch (ex) {
                console.error(ex);
            }
        });
        resizeObserver.observe(engineContainerRef.current);

        callGetModuleRules();

        async function getData() {
            let pageCode = 'Home_Gallery';
            const response = await fetch(configData.Settings.CommonApi_BaseUrl + `webcontent/getphotogallery/${pageCode}/` + getWebsiteId()).then(data => data.json());
            if (response && response.length > 0) {
                let isVideo = response[0].url.includes('.mp4');
                if (isVideo) {
                    setDynamicVideo(response[0].url);
                    setDynamicImages(null);
                } else {
                    setDynamicImages(response.map(x => x.url));
                    setDynamicVideo(null);
                }

                setImageTitle(response[0].imageTitle);
                setImageDescription(response[0].imageDescription);
            }
        }
        getData();
    }, [])

    useEffect(() => {
        setModuleSelected(getModuleSelected);
    }, [getModuleSelected])

    function isVisibleProduct(productName) {
        if (!rules)
            return false;

        try {

            switch (productName) {
                case 'ACTIVITY':
                    {
                        return rules["ACTIVITY"];
                        break;
                    }
                case 'CARRENTAL':
                case 'RENTCAR':
                    {
                        return rules["CARRENTAL"];
                        break;
                    }
                case 'CRUISE':
                    {
                        return rules["CRUISE"];
                        break;
                    }
                case 'STRUCTURE':
                    {
                        return rules["STRUCTURE"];
                        break;
                    }
                case 'TRANSFER':
                    {
                        return rules["TRANSFER"];
                        break;
                    }
                case 'TOUR':
                    {
                        return rules["TOUR"];
                        break;
                    }
                case 'FLIGHT':
                    {
                        return rules["FLIGHT"];
                        break;
                    }
                case 'TRAIN':
                    {
                        return rules["TRAIN"];
                        break;
                    }
                default: {
                    return true;
                }
            }


        } catch (ex) {
            return false;
        }
    }

    function handleClick(url, withBlank = false) {
        if (withBlank)
            window.open(url, '_blank');
        else
            window.location.href = url;
    }

    return (
        <main className="bg-white">
            <div style={{ height: "530px" }}>
                {!dynamicVideo ?
                    <div id="carouselExampleInterval" class="video-home carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            {
                                dynamicImages && dynamicImages.map((di, key) => {
                                    return <div key={key} class="carousel-item active" data-bs-interval="3000">
                                        <img class="d-block w-100" src={di} alt="" />
                                    </div>
                                }
                                )
                            }
                        </div>
                        <button class="carousel-control-prev d-none" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next d-none" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                    :
                    <video id="videoBG" className="video-home" autoPlay={true} muted loop>
                        <source src={dynamicVideo} type="video/mp4" />
                    </video>
                }
                {
                    <div id="engine-container" ref={engineContainerRef} className="container-fluid" style={{ top: '265px', bottom: '0', position: 'relative' }}>
                        <div className="slider-top-home--text">
                            <h2 className="text-white">{imageTitle}</h2>
                            {imageDescription &&
                                <a className="btn btn-outline button-trasparent button-discover" href="/webcontent/aboutmyfirsttravel">{imageDescription}</a>
                            }
                        </div>

                        <div id="engine-child" className="text-left pl-8 pr-8">
                            <div className="tab-search bg-custom2 d-flex border-bottom">
                                {isVisibleProduct('FLIGHT') && <div className={moduleSelected === "FLIGHT" ? "bg-custom" : ""} onClick={e => setModuleSelected("FLIGHT")}>
                                    {t("Product:Flight:Item")}
                                </div>}
                                {isVisibleProduct('STRUCTURE') && <div className={moduleSelected === "HOTEL" ? "bg-custom" : ""} onClick={e => setModuleSelected("HOTEL")}>
                                    {t("Product:Structure:Item")}
                                </div>}
                                {isVisibleProduct('ACTIVITY') && <div className={moduleSelected === "ACTIVITY" ? "bg-custom" : ""} onClick={e => setModuleSelected("ACTIVITY")}>
                                    {t("Product:Activity:Item")}
                                </div>}
                                {isVisibleProduct('TRANSFER') && <div onClick={() => handleClick("https://firsttravel12c.axioma.it/limo-customers/#!/home", true)}>
                                    {t("Product:Transfer:Item")}
                                </div>}
                                {isVisibleProduct('CARRENTAL') && <div className={moduleSelected === "CARRENTAL" ? "bg-custom" : ""} onClick={e => setModuleSelected("CARRENTAL")}>
                                    {t("Product:RentCar:Item")}
                                </div>}
                                {isVisibleProduct('TRAIN') && <div className={moduleSelected === "TRAIN" ? "bg-custom" : ""} onClick={e => setModuleSelected("TRAIN")}>
                                    {t("Product:Train:Item")}
                                </div>}
                                {/*<div onClick={() => handleClick(configData.Settings.Base_Url+"luxuryitineraries/italy/specialexcursions")}>
                                {t("Product:Special:Item")}
                            </div>*/}
                            </div>
                            <div className="bg-custom pt-4 pb-4 pl-2 pr-2">
                                <div className="pr-4 pl-2 ">
                                    {
                                        isVisibleProduct('STRUCTURE') && moduleSelected === 'HOTEL' && <>
                                            <h4 className="text-white">{t("Product:Structure:Item")}</h4>
                                            <StructureSearchEngine
                                                typeSearch="SRC"
                                                modeView="SEARCHENGINE" />
                                        </>
                                    }
                                    {
                                        isVisibleProduct('ACTIVITY') && moduleSelected === 'ACTIVITY' && <>
                                            <h4 className="text-white">{t("Product:Activity:Item")}</h4>
                                            <ActivitySearchEngine
                                                typeSearch="SRC"
                                                modeView="SEARCHENGINE" />
                                        </>
                                    }
                                    {
                                        isVisibleProduct('FLIGHT') && moduleSelected === 'FLIGHT' && <>
                                            <h4 className="text-white">{t("Product:Flight:Item")}</h4>
                                            <FlightSearchEngine
                                                typeSearch="SRC"
                                                modeView="SEARCHENGINE" />
                                        </>
                                    }
                                    {isVisibleProduct('CARRENTAL') && moduleSelected === 'CARRENTAL' &&
                                        <>
                                            <h4 className="text-white">{t("Product:RentCar:Item")}</h4>
                                            <CarSearchEngine
                                                typeSearch="SRC"
                                                modeView="SEARCHENGINE"
                                            />
                                        </>
                                    }
                                    {
                                        isVisibleProduct('TRAIN') && moduleSelected === 'TRAIN' && <>
                                            <h4 className="text-white">{t("Product:Train:Item")}</h4>
                                            <TrainSearchEngine
                                                typeSearch="SRC"
                                                modeView="SEARCHENGINE" />
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>


            {
                configData.Settings.WebsiteId !== "6" &&
                <div className="container" style={bannerStyle}>
                    <div className="row bg-white" style={{ marginTop: "-150px", width: "100%" }}>
                        <WebContent extraParams={[{ key: 'PAGE', value: 'HOME' }]} noShowLoading={true} />
                    </div>
                </div>
            }
        </main>
    );
}
