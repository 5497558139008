import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import { formatDateTime, formatPrice } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { M3Icon } from '../Common/M3Icon';

export const CartScadenziario = ({ addictionalProducts, selectedAddProdIds, orderNumber, mode, extScadenziario, addedMkpFee, modifiedMkpFee, selectedBuoniScontoIds }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [scadenziario, setScadenziario] = useState([]);


    useEffect(() => {
        if (!extScadenziario && configData.Settings.Cart.ShowScadenziario) {
            const getScadenziario = async (selectedAddProdIds) => { const response = callGetGetScadenziario(selectedAddProdIds); }
            const getScadenziarioFromOrder = async (orderNumber) => { const response = callGetScadenziarioFromOrder(orderNumber); }

            setIsLoading(true);
            if (orderNumber !== null && orderNumber !== undefined) {
                getScadenziarioFromOrder(orderNumber);
            } else {
                getScadenziario(selectedAddProdIds);
            }
        } else {
            setIsLoading(false);
        }

    }, [selectedAddProdIds, addedMkpFee, modifiedMkpFee, selectedBuoniScontoIds]);

    useEffect(() => {

        setScadenziario(extScadenziario);
        setIsLoading(false);

    }, [extScadenziario]);

    async function callGetGetScadenziario(addProdIds) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                AddictionalProducts: addictionalProducts,
                SelectedAddictionalProducts: addProdIds,
                AddictionalProductsSettings: { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: "Assicurazione, quote di partecipazione e altri costi" },
                AddedMkpFee: addedMkpFee,
                SelectedBuoniScontoIds: selectedBuoniScontoIds
            })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetScadenziario', requestOption);
        const response = await fetchedRes.json();

        setScadenziario(response);
        setIsLoading(false);
    }

    async function callGetScadenziarioFromOrder(orderNumber) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetOrderOutcomeScadenziario/' + orderNumber, requestOption);
        const response = await fetchedRes.json();

        setScadenziario(response);
        setIsLoading(false);
    }


    return (
        <>
            {isLoading && configData.Settings.Cart.ShowScadenziario
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        {configData.Settings.Cart.ShowScadenziario && scadenziario !== null && scadenziario !== undefined && scadenziario.deadlineGroups !== null && scadenziario.deadlineGroups !== undefined && scadenziario.deadlineGroups.length > 0
                            ? (
                                <>
                                    <div className="basket-card card mt-4 p-3 basket">
                                        <div>
                                            <M3Icon iconName="Scadenziario" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="basket titlecard">{t("MyBook:Ancillary")}</span>
                                        </div>
                                        <div className={!mode && mode !== 'QuotationTO' ? "border border-gray-500 rounded" : ""}>
                                            {scadenziario.deadlineGroups.map(group =>
                                                <div key={group.index} className="">
                                                    <div className="border-bottom border-gray-500 p-2">
                                                        <div className="titlecard">
                                                            <div className="d-inline-block"><span><label>{t("MyBook:Expiring")}&nbsp; {group.index}</label> &nbsp;&nbsp;&nbsp; {formatDateTime(group.deadlineDate, cultureName)}</span></div>
                                                            <div className="d-inline-block float-end">{formatPrice(group.amount, group.currency, cultureName)}</div>
                                                        </div>
                                                        {(orderNumber === null || orderNumber === undefined) && group.deadlineProducts.map(product =>
                                                            <div key={product.index}>{product.productName}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {
                                                !mode && mode !== 'QuotationTO' && <div className="bg-gray-400 titlecard pl-1">
                                                    <div className="d-inline-block"><h5>Totale</h5></div>
                                                    <div className="d-inline-block float-end">{formatPrice(scadenziario.totalAmount, scadenziario.totalCurrency, cultureName)}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                            : (<></>)
                        }
                    </>
                )
            }
        </>
    )
}
