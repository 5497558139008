import React, { useEffect, useState } from 'react';
import { Login } from './Auth/Login';
import { Engine } from "./Engine";
import Cookies from 'universal-cookie';


export const Home = () => {
    const [isLogged, setIsLogged] = useState(false);
    const cookies = new Cookies();

    useEffect(() => {

        const jCurrentUser = localStorage.getItem("CurrentUser");
        if (jCurrentUser !== null && jCurrentUser !== undefined) {
            setIsLogged(true);
        } else {
            setIsLogged(false);
        }
    }, [])

    return (
        <>
            {
                isLogged && <div>
                    <div><Engine /></div>
                </div>
            }
        </>
    );
}
